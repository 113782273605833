import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import React from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdCheck, MdFileCopy } from "react-icons/md";
const InfoModal = ({
  info,
  varient,
  heading,
}: {
  info?: any;
  varient?: string;
  heading?: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [copyPayment, setCopyPayment] = React.useState<boolean>(false);
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <button
        className="flex items-center justify-center gap-2 whitespace-nowrap border px-3 py-2 text-blue-500 outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:text-gray-200"
        onClick={() => {
          onOpen();
        }}
        disabled={!info}
      >
        <BsInfoCircleFill
          className={varient === "small" ? "h-4 w-4" : "h-5 w-5"}
        />
        JSON View
      </button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="z-20 bg-[#000] !opacity-50" />
        <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] min-h-[85vh] max-w-[800px]  justify-start overflow-auto sm:my-8 sm:w-full ">
          <ModalBody className="p-2">
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004] ">
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {heading}
                {""}
              </h1>
              <div className="  relative mx-[35px] flex h-fit max-h-[60vh] max-w-[75vw] flex-col !overflow-auto overflow-auto rounded-xl  border border-gray-100 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl  scrollbar-w-1.5 scrollbar-h-1.5">
                <pre className="p-2">{JSON.stringify(info, null, 2)}</pre>
                <div className="absolute right-3 top-3 outline-none">
                  {copyPayment ? (
                    <MdCheck className="cursor-pointer text-teal-500" />
                  ) : (
                    <MdFileCopy
                      className="cursor-pointer text-teal-500"
                      onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(info));
                        setCopyPayment(true);
                        setTimeout(() => {
                          setCopyPayment(false);
                        }, 1500);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default InfoModal;

import React, { useRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
// import jsonData from './settlementReportData.json';
import { downloadSettlementReport } from "api/settlement";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import toast from "react-hot-toast";

// Import report functions
import summaryReportExcelSheets from "./settlementReportTypes/summary/summaryReports";
import cardTypeVisaReportsExcelSheets from "./settlementReportTypes/cards/CardTypeVisaReports";
import cardTypeAmexReportsExcelSheets from "./settlementReportTypes/cards/CardTypeAmexReports";
import cardTypeMasterCardReportsExcelSheets from "./settlementReportTypes/cards/CardTypeMasterCardReports";
import refundReportExcelSheets from "./settlementReportTypes/refunded/refundReports";
import declinedReportExcelSheets from "./settlementReportTypes/declined/declinedReports";
import disputeReportExcelSheets from "./settlementReportTypes/dispute/disputeReports";
import chargebackReportExcelSheets from "./settlementReportTypes/chargeback/chargebackReports";
import heldReportExcelSheets from "./settlementReportTypes/held/heldReports";
import erroredReportExcelSheets from "./settlementReportTypes/errored/erroredReports";
import { FaDownload } from "react-icons/fa";

function SettlementReport(props: {
  ReportData?: any;
  handleDownloadReport?: any;
  settlementInfo?: any;
  fetchAllsettlement?:any;

}) {
  const { ReportData, settlementInfo, handleDownloadReport ,fetchAllsettlement} = props;

  const [loadingDownload, setLoadingDownload] = useState(false);

  // const [jsonData, setJsonData] = useState([]);
  let jsonData: any = [];

  const createExcel = () => {
    try {
      let templateColor = {
        yellow: "d6ab5c",
        blue: "10114f",
        black: "070708",
        white: "f5f5fc",
      };

      if (jsonData?.settlementReportCanBeGenerated) {
        // console.log({jsonData})
        const workbook = new ExcelJS.Workbook();

        summaryReportExcelSheets(jsonData, workbook, templateColor);
        cardTypeAmexReportsExcelSheets(jsonData, workbook, templateColor);
        cardTypeMasterCardReportsExcelSheets(jsonData, workbook, templateColor);
        cardTypeVisaReportsExcelSheets(jsonData, workbook, templateColor);
        refundReportExcelSheets(jsonData, workbook, templateColor);
        chargebackReportExcelSheets(jsonData, workbook, templateColor);
        disputeReportExcelSheets(jsonData, workbook, templateColor);
        heldReportExcelSheets(jsonData, workbook, templateColor);
        declinedReportExcelSheets(jsonData, workbook, templateColor);
        erroredReportExcelSheets(jsonData, workbook, templateColor);

        workbook.xlsx.writeBuffer().then((buffer: any) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `Settlement Report.xlsx`);
        });
        fetchAllsettlement()
      } else {
        alert("Cannot create Excel.");
      }
    } catch (error) {
      console.error("Error in Excel download", error);
    }
  };

  const handleExcelData = () => {
    try {
      setLoadingDownload(true);

      downloadSettlementReport(settlementInfo?.row?.original?.id)
        .then((res: any) => {
          if (res?.settlementReportCanBeGenerated) {
            console?.log("first", res);
            // setJsonData(res)
            jsonData = res;
            createExcel();
            
          } else {
            toast.error("Oops! There are no transaction of clients for the organization in the selected time period.");
          }
        })
        .catch(() => {
          toast.error("Something went wrong.");
        })
        .finally(() => {
          setLoadingDownload(false);
        });
    } catch (error) {
      console.error("Error in handling Excel data", error);
    }
  };

  return (
    <div>
      {loadingDownload ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <span onClick={handleExcelData}>
          <FaDownload
            title="Download file"
            className="h-5 w-5 cursor-pointer text-blue-500"
          />
        </span>
      )}
    </div>
  );
}

export default SettlementReport;

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./index.css";
import App from "./App";
import { ClientProvider } from "ClientProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
// console.log=()=>{}
root.render(
  <ClientProvider>
    <BrowserRouter>
      <div style={{ position: "absolute", top: "4rem" }}>
        <Toaster position="top-center" containerClassName="font-nunito"  />
      </div>
      <App />
    </BrowserRouter>
  </ClientProvider>
);

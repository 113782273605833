// import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
// import { ClientContext } from "ClientProvider";
import NotFoundPage from "NotFoundPage";
// import Card from "components/card";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes from "routes";
import { getToken } from "utils/auth";

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Dashboard");
  //  const {
  //    SettlementAPISttus,
  //    setisOpenSettlementAPISttus,
  //    isOpenSettlementAPISttus,
  //    setSettlementAPISttus,
  //  } = useContext(ClientContext);
  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);
  React.useEffect(() => {
    let token = getToken();
    if (!token) {
      navigate("/auth/sign-in", { replace: true });
      window.location.reload()
    }
  }, [navigate]);
  React.useEffect(() => {
    let token = getToken();
    if (!token) {
      navigate("/auth/sign-in", { replace: true });
      window.location.reload()
    }
  }, []);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[25px] h-full flex-none transition-all md:pr-2 lg:ml-[250px] xl:ml-[250px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(!open)}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div
              className="pt-5s mx-auto mb-auto mt-5 h-full min-h-[90vh] p-2 md:pr-2"
            >
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<NotFoundPage />} />
                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            {/* <div className="p-3">
              <Footer />
            </div> */}
          </div>
        </main>
      </div>
   
    </div>
  );
}

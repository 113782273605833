import React, { useEffect, useState } from "react";
import { FiFilter } from "react-icons/fi";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import Card from "components/card";
import DatePicker from "react-datepicker";
import Checkbox from "components/checkbox";
import moment from "moment";
import { apiAccessHistory } from "api/api_access";
import { MdHistory } from "react-icons/md";
function CrerateAPIAccessFilter(props: {
  fetchAllsettlement: () => void;
  roleData: any;
  info: any;
}) {
  const { fetchAllsettlement, roleData, info } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [filterFormData, setFilterFormData] = useState<any>({});
  const handleClose = () => {
    onClose();
    setFilterFormData({});
    setHistoryResponse([]);
  };
  const ClientData = [
    "Create client",
    "Get all clients",
    "Get client details",
    "Update client details",
    "Delete client",
  ];
  const UserData = [
    "Register user",
    "Verify otp",
    "Get user profile",
    "Get all users details",
    "Create 2fa auth details",
    "Update user profile",
    "Update user password",
    "Update user details",
    "Manage 2fa authentication",
    "Delete user profile",
    "Delete user details",
  ];
  const OrganizationData = [
    "Create organization",
    "Get all organization",
    "Get organization details",
    "Update organization details",
    "Delete organization",
  ];
  const TransactionData = [
    "Get all payments details",
    "Get payment history",
    "Refund payment",
    "Retry payment",
    "Change payment status",
  ];
  const GatewaysData = ["Get all gateways", "Get gateway details"];
  const SubscriptionData = [
    "Get all subscription",
    "Get subscription details",
    "Cancel subscription",
  ];
  const TokenData = ["Get all token", "Get token"];
  const RolesData = [
    "Create role",
    "Get role by id",
    "Get all roles",
    "Update role",
    "Delete role",
  ];
  const DashboardData = [
    "Get dashboard info",
    "Get dashboard client org_details",
    "Get wave chart",
    "Get transaction analysis",
    "Get transaction approved gateways",
    "Get transaction decline gateways",
    "Get transaction approved currency",
    "Get transaction decline currency",
    "Get client by organization",
  ];
  const ClientGatewayData = [
    "Create client gateway",
    "Delete client gateway",
    "Update client gateway",
    "Get client all gateways",
    "Get client gateway details",
    "Get charge options details",
  ];
  const [selectedClient, setSelectedClient] = useState<any>([]);
  const isAllSelectedClient =
    ClientData?.length > 0 && selectedClient?.length === ClientData?.length;
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const isAllSelectedUser =
    UserData?.length > 0 && selectedUser?.length === UserData?.length;
  const [selectedOrganization, setSelectedOrganization] = useState<any>([]);
  const isAllSelectedOrganization =
    OrganizationData?.length > 0 &&
    selectedOrganization?.length === OrganizationData?.length;
  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);
  const isAllSelectedTransaction =
    TransactionData?.length > 0 &&
    selectedTransaction?.length === TransactionData?.length;
  const [selectedGateways, setSelectedGateways] = useState<any>([]);
  const isAllSelectedGateways =
    GatewaysData?.length > 0 &&
    selectedGateways?.length === GatewaysData?.length;
  const [selecteSubscriptions, setSelectedSubscriptions] = useState<any>([]);
  const isAllSelectedSubscriptions =
    SubscriptionData?.length > 0 &&
    selecteSubscriptions?.length === SubscriptionData?.length;
  const [selecteTokens, setSelectedTokens] = useState<any>([]);
  const isAllSelectedTokens =
    TokenData?.length > 0 && selecteTokens?.length === TokenData?.length;
  const [selecteRoles, setSelectedRoles] = useState<any>([]);
  const isAllSelectedRoles =
    RolesData?.length > 0 && selecteRoles?.length === RolesData?.length;
  const [selecteDashboard, setSelectedDashboard] = useState<any>([]);
  const isAllSelectedDashboard =
    DashboardData?.length > 0 &&
    selecteDashboard?.length === DashboardData?.length;
  const [selecteClientGateway, setSelectedClientGateway] = useState<any>([]);
  const isAllSelectedClientGateway =
    ClientGatewayData?.length > 0 &&
    selecteClientGateway?.length === ClientGatewayData?.length;
  const [historyResponse, setHistoryResponse] = useState<any>([]);
  const handleCheckboxChange = (event: any) => {
    const { value, id } = event.target;
    if (value === "all") {
      if (id === "client") {
        setSelectedClient(
          selectedClient?.length === ClientData?.length ? [] : ClientData
        );
      }
      if (id === "user") {
        setSelectedUser(
          selectedUser?.length === UserData?.length ? [] : UserData
        );
      }
      if (id === "organization") {
        setSelectedOrganization(
          selectedOrganization?.length === OrganizationData?.length
            ? []
            : OrganizationData
        );
      }
      if (id === "transaction") {
        setSelectedTransaction(
          selectedTransaction?.length === TransactionData?.length
            ? []
            : TransactionData
        );
      }
      if (id === "gateway") {
        setSelectedGateways(
          selectedGateways?.length === GatewaysData?.length ? [] : GatewaysData
        );
      }
      if (id === "subscription") {
        setSelectedSubscriptions(
          selecteSubscriptions?.length === SubscriptionData?.length
            ? []
            : SubscriptionData
        );
      }
      if (id === "token") {
        setSelectedTokens(
          selecteTokens?.length === TokenData?.length ? [] : TokenData
        );
      }
      if (id === "role") {
        setSelectedRoles(
          selecteRoles?.length === RolesData?.length ? [] : RolesData
        );
      }
      if (id === "dashboard") {
        setSelectedDashboard(
          selecteDashboard?.length === DashboardData?.length
            ? []
            : DashboardData
        );
      }

      if (id === "clientgateway") {
        setSelectedClientGateway(
          selecteClientGateway?.length === ClientGatewayData?.length
            ? []
            : ClientGatewayData
        );
      }
    } else if (value === "-1") {
      if (id === "client") {
        setSelectedClient([]);
      }
      if (id === "user") {
        setSelectedUser([]);
      }
      if (id === "organization") {
        setSelectedOrganization([]);
      }
      if (id === "transaction") {
        setSelectedTransaction([]);
      }
      if (id === "gateway") {
        setSelectedGateways([]);
      }
      if (id === "subscription") {
        setSelectedSubscriptions([]);
      }
      if (id === "token") {
        setSelectedTokens([]);
      }
      if (id === "role") {
        setSelectedRoles([]);
      }
      if (id === "dashboard") {
        setSelectedDashboard([]);
      }
      if (id === "clientgateway") {
        setSelectedClientGateway([]);
      }
    } else {
      if (id === "client") {
        if (selectedClient?.includes(value)) {
          setSelectedClient(
            selectedClient?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedClient([...selectedClient, value]);
        }
      }
      if (id === "user") {
        if (selectedUser?.includes(value)) {
          setSelectedUser(
            selectedUser?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedUser([...selectedUser, value]);
        }
      }
      if (id === "organization") {
        if (selectedOrganization?.includes(value)) {
          setSelectedOrganization(
            selectedOrganization?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedOrganization([...selectedOrganization, value]);
        }
      }
      if (id === "transaction") {
        if (selectedTransaction?.includes(value)) {
          setSelectedTransaction(
            selectedTransaction?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedTransaction([...selectedTransaction, value]);
        }
      }
      if (id === "gateway") {
        if (selectedGateways?.includes(value)) {
          setSelectedGateways(
            selectedGateways?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedGateways([...selectedGateways, value]);
        }
      }
      if (id === "subscription") {
        if (selecteSubscriptions?.includes(value)) {
          setSelectedSubscriptions(
            selecteSubscriptions?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedSubscriptions([...selecteSubscriptions, value]);
        }
      }
      if (id === "token") {
        if (selecteTokens?.includes(value)) {
          setSelectedTokens(
            selecteTokens?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedTokens([...selecteTokens, value]);
        }
      }
      if (id === "role") {
        if (selecteRoles?.includes(value)) {
          setSelectedRoles(
            selecteRoles?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedRoles([...selecteRoles, value]);
        }
      }
      if (id === "dashboard") {
        if (selecteDashboard?.includes(value)) {
          setSelectedDashboard(
            selecteDashboard?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedDashboard([...selecteDashboard, value]);
        }
      }
      if (id === "clientgateway") {
        if (selecteClientGateway?.includes(value)) {
          setSelectedClientGateway(
            selecteClientGateway?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedClientGateway([...selecteClientGateway, value]);
        }
      }
    }
  };
  // // console.log("filterFormData", filterFormData);

  useEffect(() => {
    if (filterFormData?.ending_date && filterFormData?.starting_date) {
      let filterData = historyResponse?.filter((f: any) => {
        return (
          moment(filterFormData?.starting_date).format("YYYY-MM-DD") <=
            moment(f?.time).format("YYYY-MM-DD") &&
          moment(filterFormData?.ending_date).format("YYYY-MM-DD") >=
            moment(f?.time).format("YYYY-MM-DD")
        );
      });
      setHistoryResponse(filterData);
    } else {
      setHistoryResponse(info?.row?.original?.api_call_history);
    }
  }, [filterFormData?.ending_date]);

  const apiAccessHistoryFun = () => {
    let payload = {
      start_date: filterFormData?.starting_date
        ? moment(filterFormData?.starting_date).format("YYYY-MM-DD, HH:mm:ss")
        : null,
      end_date: filterFormData?.ending_date
        ? moment(filterFormData?.ending_date).format("YYYY-MM-DD, HH:mm:ss")
        : null,
      // selected_endpoints: {
      //     "client_operetaion_permission": selectedClient?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     user_operetaion_permission: selectedUser?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     organization_operetaion_permission: selectedOrganization?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     transaction_operation_permission: selectedTransaction?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     gateways_operetaion_permission: selectedGateways?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     subscriptions_operetaion_permission: selecteSubscriptions?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     token_operation_permission: selecteTokens?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     role_operation_permission: selecteRoles?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     dashboard_operation_permission: selecteDashboard?.map((m: any) => m?.toLowerCase().replace(/ /g, "_")),
      //     "client_gateway_operations": selecteClientGateway?.map((m: any) => m?.toLowerCase().replace(/ /g, "_"))

      // }
    };

    apiAccessHistory(payload, info?.row?.original?.id)
      .then((res: any) => {
        setIsOpenHistory(true);
        setHistoryResponse(res);
      })
      .catch((error) => {});
  };

  // // console.log("historyResponse", historyResponse);

  return (
    <>
      <MdHistory
        className="h-5 w-5 cursor-pointer font-bold text-blue-500"
        title="API Call History"
        onClick={() => {
          setHistoryResponse(info?.row?.original?.api_call_history);
          onOpen();
        }}
      />
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />

        <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[100vh] max-w-[800px] justify-center !p-3 sm:my-8 sm:w-full">
          <ModalBody>
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004]">
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {"APIs Call History"}
              </h1>

              <div className="max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1 sm:max-h-[calc(100vh-300px)]">
                <div className="p-3">
                  <label
                    htmlFor="name-input"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="block w-full rounded-lg border p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    placeholder="Namet"
                    value={info?.row?.original?.name}
                    disabled={true}
                  />
                </div>
                <div className="p-3">
                  <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                    Date Range
                  </label>
                  {true && (
                    <DatePicker
                      className="w-full rounded border p-3 outline-none"
                      placeholderText="Select Date Range"
                      startDate={
                        filterFormData?.starting_date &&
                        new Date(filterFormData?.starting_date)
                      }
                      endDate={
                        filterFormData?.ending_date &&
                        new Date(filterFormData?.ending_date)
                      }
                      selectsRange={true}
                      isClearable={true}
                      onChange={(dateRange: any) => {
                        const [a, b] = dateRange;
                        setFilterFormData((prev: any) => {
                          return {
                            ...prev,
                            starting_date: a,
                            ending_date: b,
                          };
                        });
                      }}
                    
                      
                    />
                  )}
                </div>
                <div className="p-3">
                  {false && (
                    <button
                      onClick={() => {
                        // apiAccessHistoryFun()
                      }}
                      className="linear rounded-xl bg-blue-100  px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-blue-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                    >
                      {"Apply"}
                    </button>
                  )}
                </div>
                <div className="p-3">
                  <p>
                    <span className="text-lg text-gray-900">
                      Result shown for Date Range:{" "}
                    </span>
                    {filterFormData?.starting_date &&
                    filterFormData?.ending_date ? (
                      <span className="rounded border px-2 text-lg text-gray-900">
                        {filterFormData?.starting_date &&
                          moment(filterFormData?.starting_date).format(
                            "YYYY-MM-DD"
                          )}{" "}
                        {" - "}{" "}
                        {filterFormData?.ending_date &&
                          moment(filterFormData?.ending_date).format(
                            "YYYY-MM-DD"
                          )}
                      </span>
                    ) : (
                      <span className="rounded border px-2 text-lg text-gray-900">
                        {"All"}
                      </span>
                    )}
                  </p>
                </div>

                <div className="relative my-3 overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
                    <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Token Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Backoffice API
                        </th>

                        <th scope="col" className="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyResponse?.length > 0 ? (
                        historyResponse.map((m: any) => {
                          return (
                            <tr className="border-b bg-white text-gray-900 dark:border-gray-700 dark:bg-gray-800">
                              <td className="px-6 py-4">
                                {info?.row?.original?.name}
                              </td>
                              <td className="px-6 py-4 capitalize">
                                {m?.apiName?.replace(/_/g, " ")}
                              </td>
                              <td className="px-6 py-4">
                                {m?.time &&
                                  moment(m?.time).format("YYYY-MM-DD")}
                              </td>
                              <td className="px-6 py-4">
                                {m?.time && moment(m?.time).format("HH:mm:ss")}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className=" text-center text-gray-900">
                          <td colSpan={4} className="py-3">
                            {"No records found."}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {false && (
                  <div className="my-3 p-3">
                    <label className=" mb-0 block text-sm font-medium text-gray-900 dark:text-white">
                      All Backoffice APIs
                    </label>
                    {/* <Card> */}
                    <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="client"
                              value={isAllSelectedClient ? -1 : "all"}
                              checked={isAllSelectedClient}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Client
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {ClientData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="client"
                                    value={data}
                                    checked={selectedClient.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="user"
                              value={isAllSelectedUser ? -1 : "all"}
                              checked={isAllSelectedUser}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              User
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {UserData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="user"
                                    value={data}
                                    checked={selectedUser.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="organization"
                              value={isAllSelectedOrganization ? -1 : "all"}
                              checked={isAllSelectedOrganization}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Organization
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {OrganizationData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="organization"
                                    value={data}
                                    checked={
                                      selectedOrganization.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="transaction"
                              value={isAllSelectedTransaction ? -1 : "all"}
                              checked={isAllSelectedTransaction}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Transaction
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {TransactionData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="transaction"
                                    value={data}
                                    checked={
                                      selectedTransaction.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="gateway"
                              value={isAllSelectedGateways ? -1 : "all"}
                              checked={isAllSelectedGateways}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Gateways
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {GatewaysData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="gateway"
                                    value={data}
                                    checked={
                                      selectedGateways.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="subscription"
                              value={isAllSelectedSubscriptions ? -1 : "all"}
                              checked={isAllSelectedSubscriptions}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Subscriptions
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {SubscriptionData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="subscription"
                                    value={data}
                                    checked={
                                      selecteSubscriptions.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="token"
                              value={isAllSelectedTokens ? -1 : "all"}
                              checked={isAllSelectedTokens}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Tokens
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {TokenData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="token"
                                    value={data}
                                    checked={selecteTokens.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="role"
                              value={isAllSelectedRoles ? -1 : "all"}
                              checked={isAllSelectedRoles}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Roles
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {RolesData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="role"
                                    value={data}
                                    checked={selecteRoles.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="dashboard"
                              value={isAllSelectedDashboard ? -1 : "all"}
                              checked={isAllSelectedDashboard}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Dashboard
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {DashboardData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="dashboard"
                                    value={data}
                                    checked={
                                      selecteDashboard.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="clientgateway"
                              value={isAllSelectedClientGateway ? -1 : "all"}
                              checked={isAllSelectedClientGateway}
                              onChange={handleCheckboxChange}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Client Gateway
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {ClientGatewayData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="clientgateway"
                                    value={data}
                                    checked={
                                      selecteClientGateway.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* </Card> */}
                  </div>
                )}
              </div>

              <div className="mt-5 flex justify-end gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-indigo-50  px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                {false && (
                  <button
                    onClick={() => {
                      apiAccessHistoryFun();
                    }}
                    className="linear rounded-xl bg-blue-100  px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-blue-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  >
                    {"Apply"}
                  </button>
                )}
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenHistory} onClose={() => {}}>
        <ModalOverlay className="z-20 bg-[#000] !opacity-50" />
        <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[100vh] max-w-[800px] justify-center !p-3 sm:my-8 sm:w-full">
          <ModalBody>
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004]">
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {"Filter Response"}
              </h1>

              <div className="max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1 sm:max-h-[calc(100vh-300px)]">
                <h4>
                  Date Range:{" "}
                  {moment(filterFormData?.starting_date).format("YYYY/MM/DD") +
                    " - " +
                    moment(filterFormData?.ending_date).format("YYYY/MM/DD")}
                </h4>
              </div>

              <div className="mt-5 flex justify-end gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={() => setIsOpenHistory(false)}
                  className="linear rounded-xl bg-indigo-50  px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CrerateAPIAccessFilter;

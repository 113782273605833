import React, { useEffect, useState } from "react";
import { VscError } from "react-icons/vsc";
import { FaCircleCheck } from "react-icons/fa6";
import { useParams } from "react-router-dom";
// import { paymentProcessingStatus } from 'api/payments';
import { Spinner } from "@chakra-ui/react";
import { BiSolidError } from "react-icons/bi";
import axios from "axios";
// import { SiAuth0 } from "react-icons/si";
import { RiRotateLockFill } from "react-icons/ri";
import { APIPath } from "baserURL";
import { BsClockHistory } from "react-icons/bs";
function PaymentStatus() {
  // let [params] = useSearchParams()
  let urlParms = useParams();
  // alert(JSON.stringify(urlParms))
  // const SearchParam = Object.fromEntries([...params])
  const [paymentProcessingData, setPaymentProcessingData] = useState<any>("");
  const [paymentProcessingReason, setPaymentProcessingReason] =
    useState<any>("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getPaymentProcessingStatus();
  }, []);
  const getPaymentProcessingStatus = () => {
    let url = APIPath + "/api/v1/payment/processing";
    const { gateway_id, client_id, payment_request_id } = urlParms;
    let payload = {
      gateway_id: gateway_id,
      client_id: client_id,
      payment_request_id: payment_request_id,
    };
    setLoading(true);
    axios
      .post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        let res = response?.data;
        setPaymentProcessingData(res?.status);
        setPaymentProcessingReason(res?.reason);
        setLoading(false);
      })
      .catch((error) => {
        // // console.log("error", error)
        setPaymentProcessingData("");
        setPaymentProcessingReason("");
        setLoading(false);
      });
    // paymentProcessingStatus(payload).then((res) => {
    //     // // console.log("Response", res)
    //     setPaymentProcessingData(res?.status)
    //     setPaymentProcessingReason(res?.reason)
    //     setLoading(false)
    // }).catch((error) => {
    //     // console.log("error", error)
    //     setPaymentProcessingData("")
    //     setPaymentProcessingReason("")

    //     setLoading(false)
    // })
  };
  return (
    <div className="text-center">
      <div className="flex !h-[70vh] flex-col items-center justify-center ">
        {!loading ? (
          <div className="flex !h-[70vh] flex-col items-center   justify-center">
            <h1>
              {paymentProcessingData == "APPROVED" ? (
                <>
                  <FaCircleCheck className="mx-auto text-9xl  text-green-500" />
                  <h3 className="0 my-3 font-bold">
                    Payment has been Successfully done.
                  </h3>
                </>
              ) : paymentProcessingData == "DECLINED" ? (
                <>
                  <VscError className="mx-auto text-9xl  text-red-500" />
                  <div className="text-start">
                    <h3 className="my-3 font-bold">
                      Payment has been declined.
                    </h3>
                    {paymentProcessingReason && (
                      <h3 className="my-3 font-bold">
                        Reason: {paymentProcessingReason}
                      </h3>
                    )}
                  </div>
                </>
              ) : paymentProcessingData == "PENDING" ? (
                <>
                  <BsClockHistory className="text-yello-500 mx-auto  text-9xl" />
                  <div className="text-start">
                    <h3 className="my-3 font-bold">
                      Payment is still pending.
                    </h3>
                    {/* {paymentProcessingReason && <h3 className='font-bold my-3'>Reason: {paymentProcessingReason}</h3>} */}
                  </div>
                </>
              ) : paymentProcessingData == "ERRORED" ? (
                <div className="">
                  <BiSolidError className="mx-auto text-9xl text-red-500" />
                  <div className="text-start">
                    <h3 className="my-3 font-bold">
                      There is some error occurred with this transaction.
                    </h3>
                    {paymentProcessingReason && (
                      <h3 className="my-3 font-bold">
                        Reason: {paymentProcessingReason}
                      </h3>
                    )}
                  </div>
                </div>
              ) : paymentProcessingData == "AUTHORIZED" ? (
                <div className="">
                  <RiRotateLockFill className="mx-auto text-9xl text-orange-400" />
                  <div className="text-start">
                    {/* <h3 className='font-bold my-3'>There is some error occurred with this transaction.</h3> */}
                    {paymentProcessingReason && (
                      <h3 className="my-3 font-bold">
                        {paymentProcessingReason}
                      </h3>
                    )}
                  </div>
                </div>
              ) : (
                <span className="mx-auto  text-lg">Something went wrong !</span>
              )}
            </h1>
          </div>
        ) : (
          <>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="white"
              color="blue"
              style={{ height: "50px", width: "50px" }}
            />
            <br />
            <span className="mx-2 text-lg font-normal">
              Please wait, your transaction is being processed. If you want you
              can close this tab.
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default PaymentStatus;

import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useEffect, useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useOnClickOutsideSingel } from "utils/useOnClickOutside";
import InputField from "components/fields/InputField";
const FilterModal = ({
  subClientsData,
  filter,
  setFilter,
  fetchSubscriptions,
}: {
  subClientsData?: any;
  setPage?: any;
  filter?: any;
  setFilter?: (e: any) => void;
  roleData?: any;
  filterData: () => void;
  fetchSubscriptions: any;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [clientdropdown, setClientDropdown] = React.useState<boolean>(false);
  const [localFilter, setLocalFilter] = React.useState({
    clients: [],
    interval: [],
    duration: null,
    status: [],
  });
  const clientRef = useRef();
  useOnClickOutsideSingel(clientRef, () => {
    setClientDropdown(false);
  });
  const handleClose = () => {
    onClose();
  };

  const handleClientSubChange = (values: any) => {
    setLocalFilter((prev: any) => {
      return {
        ...prev,
        clients: values,
      };
    });
  };
  const handleIntervalChange = (values: any) => {
    setLocalFilter((prev: any) => {
      return {
        ...prev,
        interval: values,
      };
    });
  };
  const handleStatusChange = (values: any) => {
    setLocalFilter((prev: any) => {
      return {
        ...prev,
        status: values,
      };
    });
  };
  const handleValueChange = (e: any) => {
    const value = e.target.value;
    setLocalFilter((prev: any) => {
      return {
        ...prev,
        duration: value,
      };
    });
  };
  return (
    <>
      <button
        className="my-1 rounded-full bg-indigo-600 px-3 py-2 text-white hover:bg-indigo-500 md:my-3"
        onClick={() => {
          onOpen();
        }}
      >
        Apply Filter
      </button>

      {(filter?.clients?.length > 0 ||
        filter?.duration > 0 ||
        filter?.status?.length > 0 ||
        filter?.interval?.length > 0) && (
        <button
          className="my-1 rounded-full bg-red-500 px-3 py-2 text-white hover:bg-red-400 md:my-3"
          onClick={() => {
            setFilter({
              clients: [],
              interval: [],
              duration: null,
              status: [],
            });
            setLocalFilter({
              clients: [],
              interval: [],
              duration: null,
              status: [],
            });
            fetchSubscriptions();
          }}
        >
          Clear Filter
        </button>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] !min-h-[100vh] min-h-[85vh]  max-w-[800px] justify-start overflow-auto  sm:my-8 sm:w-full ">
          <ModalBody className="p-2">
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004] ">
              <h1 className="p-5 px-[30px] text-2xl font-bold">Apply Filter</h1>
              <div className="mx-[35px]">
                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Clients :
                  </label>
                  <div className="relative" ref={clientRef}>
                    <MultiSelect
                      options={subClientsData}
                      value={localFilter?.clients}
                      onChange={handleClientSubChange}
                      labelledBy={"Select"}
                      isCreatable={false}
                    />
                  </div>
                </div>
                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Intervals :
                  </label>
                  <div className="relative" ref={clientRef}>
                    <MultiSelect
                      options={["WEEKLY", "MONTHLY", "YEARLY"].map((m: any) => {
                        return {
                          label: m,
                          value: m,
                        };
                      })}
                      value={localFilter?.interval}
                      onChange={handleIntervalChange}
                      labelledBy={"Select"}
                      isCreatable={false}
                    />
                  </div>
                </div>
                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Duration :
                  </label>
                  <div className="relative">
                    <InputField
                      variant="auth"
                      extra="mb-1"
                      label=""
                      placeholder="Duration"
                      id="Duration"
                      type="number"
                      value={localFilter?.duration ?? ""}
                      onChange={handleValueChange}
                    />
                  </div>
                </div>
                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Status :
                  </label>
                  <div className="relative z-[1002]" ref={clientRef}>
                    <MultiSelect
                      options={["ACTIVE", "COMPLETED", "CANCELLED"].map(
                        (m: any) => {
                          return {
                            label: m,
                            value: m,
                          };
                        }
                      )}
                      value={localFilter?.status}
                      onChange={handleStatusChange}
                      labelledBy={"Select"}
                      isCreatable={false}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    setFilter(localFilter);
                    onClose();
                  }}
                  className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                >
                  {isLoading ? (
                    <DivLoader className="mx-0 h-5 w-5 border-indigo-500 px-0" />
                  ) : (
                    "Apply"
                  )}
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default FilterModal;

import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import { getGateways, getTimeZone, refundPayment } from "api/payments";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { FaChevronDown } from "react-icons/fa";
import { MdOutlineReplayCircleFilled, MdPeople } from "react-icons/md";
import { MultiSelect } from "react-multi-select-component";
import { DateRangePicker } from "rsuite";
import { convertToFloat } from "utils/formatNumber";
import { useOnClickOutsideSingel } from "utils/useOnClickOutside";
import { GatewayTypes } from "views/admin/clients/components/CreateClientGatewaysModal";
import TripleToggleSwitch from "./TripleToggleSwitch";
import DateRange from "components/fields/DateRange";
import DatePicker from "react-datepicker";
interface option {
  label: string;
  value: string;
}
const FilterModal = ({
  clients,
  allGateways,
  allCardType,
  paymentType,
  curretClient,
  setCurrentClient,
  setPage,
  handleSelectPayment,
  filterData,
  fetchPayments,
  varient,
  filter,
  setFilter,
  roleData,
  setLocalFilters,
  localFilters,
  page,
}: {
  clients?: any;
  allGateways?: any;
  allCardType?: string[];
  paymentType?: any;
  curretClient?: any;
  setCurrentClient?: any;
  setPage?: any;
  handleSelectPayment?: (e: any) => void;
  filterData?: () => void;
  fetchPayments?: () => void;
  varient?: string;
  filter?: any;
  setFilter?: (e: any) => void;
  roleData?: any;
  setLocalFilters?: (e: any) => void;
  localFilters?: any;
  page?: any;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [clientdropdown, setClientDropdown] = React.useState<boolean>(false);
  const [paymentdropdown, setPaymentDropdown] = React.useState<boolean>(false);
  const [cardtypedropdown, setCardtypeDropdown] =
    React.useState<boolean>(false);
  const [dropdown, setDropdown] = React.useState<boolean>(false);
  const [paymentName, setPaymentName] = React.useState<boolean>(false);
  const [CardOption, setCardOption] = React.useState<Array<option>>([]);
  const [timeZone, setTimeZone] = React.useState<any>();
  const [dates, setDates] = React.useState<[Date, Date]>();
  const [selectedStatus, setSelectedStatus] = React.useState<Array<option>>([]);
  const status: Array<option> = [
    { label: "APPROVED", value: "APPROVED" },
    { label: "REFUNDED", value: "REFUNDED" },
    { label: "AUTHORIZED", value: "AUTHORIZED" },
    { label: "CAPTURED", value: "CAPTURED" },
    { label: "PENDING", value: "PENDING" },
    { label: "CANCELLED", value: "CANCELLED" },
    { label: "DECLINED", value: "DECLINED" },
    { label: "ERRORED", value: "ERRORED" },
    { label: "REFUND DECLINED", value: "REFUND DECLINED" },
    { label: "CHARGEBACK", value: "CHARGEBACK" },
    { label: "REJECTED", value: "REJECTED" },
  ];
  const [gateways, setGateways] = React.useState<Array<option>>([]);
  const [seletedGateways, setSelectedGateways] = React.useState<Array<option>>(
    []
  );
  const [selectedCardType, setSelectedCardType] = React.useState<Array<option>>(
    []
  );

  const [client, setClient] = React.useState<Array<option>>([]);
  const [selectedClient, setSelectedClient] = React.useState<Array<option>>([]);
  React.useEffect(() => {
    setPaymentName(roleData[0]?.payment?.value?.payment_show_method_name);
    if (allCardType !== undefined) {
      setCardOption(
        allCardType?.map((i) => {
          return { label: i, value: i };
        })
      );
    }
    const gateway = allGateways?.map((i: any) => {
      return { label: i.name, value: i.id };
    });
  }, [allCardType, gateways]);

  const clientRef = useRef();
  useOnClickOutsideSingel(clientRef, () => {
    setClientDropdown(false);
  });
  const payRef = useRef();
  useOnClickOutsideSingel(payRef, () => {
    setPaymentDropdown(false);
  });
  const cardRef = useRef();
  useOnClickOutsideSingel(cardRef, () => {
    setCardtypeDropdown(false);
  });
  const getRef = useRef();
  useOnClickOutsideSingel(getRef, () => {
    setDropdown(false);
  });
  const fetchTimeZone = () => {
    getTimeZone()
      .then((data) => {
        setTimeZone(data ?? []);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            "Something went wrong while fetching time zone"
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchTimeZone();
  }, []);
  const handleClose = () => {
    onClose();
    setFilter({
      status: "all",
      client: [],
      dates: null,
      payment_status: [],
      card_type: [],
      gateway: [],
      timezone: "",
    });
    setSelectedCardType([]);
    setSelectedClient([]);
    setSelectedGateways([]);
    setSelectedStatus([]);
  };
  const handleOnchange = (e: any, value?: string) => {
    if (value === "dates") {
      setFilter({ ...filter, [value]: e });
    } else if (e?.target?.name === "gateway") {
      if (e?.target?.value === "all2") {
        if (filter?.allGateways?.length === allGateways?.length) {
          setFilter({
            ...filter,
            [e.target.name]: [],
          });
        } else {
          setFilter({
            ...filter,
            [e.target.name]: allGateways?.map((i: any) => i?.id) ?? [],
          });
        }
      } else {
        const data = filter?.allGateways?.filter(
          (i: any) => i === e.target.value
        );
        if (data?.length > 0) {
          const data1 = filter?.allGateways?.filter(
            (i: any) => i !== e.target.value
          );
          setFilter({
            ...filter,
            [e.target.name]: data1,
          });
        } else {
          setFilter({
            ...filter,
            [e.target.name]: [...(filter?.gateway ?? []), e.target.value],
          });
        }
      }
    } else if (e?.target?.name === "payment_status") {
      if (e?.target?.value === "all1") {
        if (filter?.payment_status?.length === 8) {
          setFilter({
            ...filter,
            [e.target.name]: [],
          });
        } else {
          setFilter({
            ...filter,
            [e.target.name]: status,
          });
        }
      } else {
        const data = filter?.payment_status?.filter(
          (i: any) => i === e.target.value
        );
        if (data?.length > 0) {
          const data1 = filter?.payment_status?.filter(
            (i: any) => i !== e.target.value
          );
          setFilter({
            ...filter,
            [e.target.name]: data1,
          });
        } else {
          setFilter({
            ...filter,
            [e.target.name]: [
              ...(filter?.payment_status ?? []),
              e.target.value,
            ],
          });
        }
      }
    } else if (e?.target?.name === "card_type") {
      if (e?.target?.value === "all5") {
        if (filter?.card_type?.length === 8) {
          setFilter({
            ...filter,
            [e.target.name]: [],
          });
        } else {
          setFilter({
            ...filter,
            [e.target.name]: CardOption,
          });
        }
      } else {
        const data = filter?.card_type?.filter(
          (i: any) => i === e.target.value
        );
        if (data?.length > 0) {
          const data1 = filter?.card_type?.filter(
            (i: any) => i !== e.target.value
          );
          setFilter({
            ...filter,
            [e.target.name]: data1,
          });
        } else {
          setFilter({
            ...filter,
            [e.target.name]: [...(filter?.card_type ?? []), e.target.value],
          });
        }
      }
    } else if (e?.target?.name === "client") {
      if (e?.target?.value === "all") {
        if (filter?.client?.length === clients?.[0]?.length) {
          setFilter({
            ...filter,
            [e.target.name]: [],
          });
          return;
        } else {
          setFilter({
            ...filter,
            [e.target.name]: clients?.[0]?.map((i: any) => i?.client_id) ?? [],
          });
        }
      } else {
        const data = filter?.client?.filter((i: any) => i === e.target.value);
        if (data?.length > 0) {
          const data1 = filter?.client?.filter(
            (i: any) => i !== e.target.value
          );
          setFilter({
            ...filter,
            [e.target.name]: data1,
          });
        } else {
          setFilter({
            ...filter,
            [e.target.name]: [...(filter?.client ?? []), e.target.value],
          });
        }
      }
    } else {
      setFilter({ ...filter, [e.target.name]: e.target.value });
    }
  };

  // Handle Status
  const handleStatusChange = (e: any) => {
    setSelectedStatus(e);
    const data: Array<string> = e.map((i: option) => i.value);
    setFilter({
      ...filter,
      payment_status: data?.length === 0 ? [] : data,
    });
  };

  // Handle Gateway
  const handleGatewaysChange = (e: any) => {
    setSelectedGateways(e);
    const data: Array<string> = e.map((i: option) => i.value);
    setFilter({
      ...filter,
      gateway: data?.length === 0 ? [] : data,
    });
  };

  // Handle Card Type
  const handleCardTypeChange = (e: any) => {
    setSelectedCardType(e);
    const data: Array<string> = e.map((i: option) => i.value);
    setFilter({
      ...filter,
      card_type: data?.length === 0 ? [] : data,
    });
  };
  return (
    <>
      <button
        className="my-1 rounded-full bg-indigo-600 px-3 py-2 text-white hover:bg-indigo-500 md:my-3"
        onClick={() => {
          onOpen();
        }}
      >
        Apply Filter
      </button>
      {(filter?.gateway?.length > 0 ||
        filter?.client?.length > 0 ||
        filter?.dates ||
        filter?.payment_status?.length > 0 ||
        filter?.card_type?.length > 0 ||
        filter?.timezone) && (
        <button
          className="my-1 rounded-full bg-red-500 px-3 py-2 text-white hover:bg-red-400 md:my-3"
          onClick={() => {
            setFilter({
              status: "all",
              client: [],
              dates: null,
              payment_status: [],
              card_type: [],
              gateway: [],
              timezone: "",
            });
            setSelectedCardType([]);
            setSelectedClient([]);
            setSelectedGateways([]);
            setSelectedStatus([]);
          }}
        >
          Clear Filter
        </button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] min-h-[100vh] max-w-[800px]  justify-start overflow-auto sm:my-8 sm:w-full ">
          <ModalBody className="p-2">
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004] ">
              <h1 className="p-5 px-[30px] text-2xl font-bold ">
                Apply Filter
              </h1>

              <div className="mx-[35px] ">
                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Clients :
                  </label>
                  <MultiSelect
                    options={clients?.[0]?.map((m: any) => {
                      return {
                        label: m?.name,
                        value: m?.client_id,
                      };
                    })}
                    value={clients?.[0]
                      ?.map((m: any) => {
                        return {
                          label: m?.name,
                          value: m?.client_id,
                        };
                      })
                      .filter((f: any) => filter?.client?.includes(f?.value))}
                    onChange={(e: any) => {
                      setFilter((prev: any) => {
                        return {
                          ...prev,
                          client: e.map((m: any) => m?.value),
                        };
                      });
                      setLocalFilters((prev: any) => {
                        return {
                          ...prev,
                          client: e.map((m: any) => m?.value),
                        };
                      });
                    }}
                    labelledBy={"Select"}
                    isCreatable={true}
                  />
                </div>
                <div className="mb-3 items-center gap-4">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Date :
                  </label>
                  <DatePicker
                    className="w-full rounded border  p-3 outline-none"
                    placeholderText="Select Date Range"
                    startDate={
                      filter?.start_date ? new Date(filter?.start_date) : null
                    }
                    endDate={
                      filter?.end_date ? new Date(filter?.end_date) : null
                    }
                    selectsRange={true}
                    isClearable={true}
                    onChange={(dateRange) => {
                      const [a, b] = dateRange;
                      setFilter((prev: any) => {
                        return {
                          ...prev,
                          start_date: a,
                          end_date: b,
                        };
                      });
                      if (a != null && b != null) {
                        setFilter((prev: any) => {
                          return {
                            ...prev,
                            dates: [a, b],
                          };
                        });
                      } else {
                        setFilter((prev: any) => {
                          return {
                            ...prev,
                            dates: null,
                          };
                        });
                      }
                    }}
                  />
                </div>

                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Payments status :
                  </label>
                  {/* FOR STATUS */}
                  <MultiSelect
                    options={status}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    labelledBy={"Select"}
                    isCreatable={true}
                  />
                </div>

                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Gateway :
                  </label>
                  {/* FOR GATEWAYS */}
                  {allGateways && (
                    <MultiSelect
                      options={allGateways?.map((i: any) => {
                        return { label: i.name, value: i.id };
                      })}
                      value={seletedGateways}
                      onChange={handleGatewaysChange}
                      labelledBy={"Select"}
                      isCreatable={true}
                    />
                  )}
                </div>

                <div className="mb-3 block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Time zone :
                  </label>
                  <select
                    id="timezone"
                    name="timezone"
                    value={filter?.timezone}
                    onChange={(e) => handleOnchange(e)}
                    className="mt-1 flex h-10 w-full items-center justify-center rounded-xl border  bg-white/0 p-2 text-sm text-gray-700 outline-none dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                  >
                    <option value="">Select TimeZone</option>
                    {timeZone?.map((data: any) => {
                      return (
                        <option
                          value={data?.label}
                        >{`${data?.label}(${data?.value})`}</option>
                      );
                    })}
                  </select>
                </div>

                <div className="mb-3  block items-center gap-3">
                  <label className="block pb-[6px] text-sm font-bold text-gray-900 dark:text-white">
                    Card type :
                  </label>

                  {/* FOR CARD TYPES */}
                  <MultiSelect
                    options={CardOption}
                    value={selectedCardType}
                    onChange={handleCardTypeChange}
                    labelledBy={"Select"}
                    isCreatable={true}
                  />
                </div>
              </div>

              <div className="mt-5 flex gap-2 px-[30px] pb-[10px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Clear filter
                </button>
                <button
                  onClick={() => {
                    setPage(1);
                    filterData();

                    onClose();
                  }}
                  className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                >
                  {isLoading ? (
                    <DivLoader className="mx-0 h-5 w-5 border-indigo-500 px-0" />
                  ) : (
                    "Apply"
                  )}
                </button>
              </div>
              {/* </div> */}
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default FilterModal;

import { useEffect, useState, useContext } from "react";
import { FiAlignJustify, FiSearch } from "react-icons/fi";


interface SearchProps {
  onSearch: (query: string) => void;
}

const Searchbox: React.FC<SearchProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const delay = 1300;
    const debounceSearch = setTimeout(async () => {
      //// console.log("searchTerm",searchTerm);
      await onSearch(searchTerm);
    }, delay);

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [searchTerm]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ser_txt = event.target.value || "";
    //// console.log(ser_txt);
    setSearchTerm(ser_txt);
  };

  return (


    // <div className="relative mt-[3px] flex h-[61px] w-fit flex-grow items-center justify-around gap-3 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-fit md:flex-grow-0 md:gap-1 xl:w-fit xl:gap-3">
    <div className="relative mt-[3px] resserc flex  h-[61px] w-fit xxl:flex-grow items-center justify-around gap-3 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-fit md:flex-grow-0 md:gap-1 xl:w-fit xl:gap-3">
      <div className="flex h-full resserc items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          name="searchVal"
          value={searchTerm}
          placeholder="Search name"
          autoComplete="off"
          onChange={handleInputChange}
          className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </div>
    </div >

  );
};

export default Searchbox;
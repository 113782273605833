import { FaClock } from "react-icons/fa";
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineReplayCircleFilled,
} from "react-icons/md";
const StatusStyle = ({ status, value }: { status: string; value: string }) => {
  return (
    <>
      {status === "COMPLETED" && (
        <>
          <MdCheckCircle className="mr-1 mt-1 h-5 w-5 text-teal-500" />
          <p className="rounded-lg bg-green-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-green-50">
            <span className="px-2 text-center text-green-500">{value}</span>
          </p>
        </>
      )}

      {status === "ACTIVE" && (
        <>
          <MdOutlineReplayCircleFilled className="mr-1 mt-1 h-5 w-5 text-blue-500" />
          <p className="rounded-lg bg-blue-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-blue-50">
            <span className="px-2 text-center text-blue-500">{value}</span>
          </p>
        </>
      )}

      {status === "CANCELLED" && (
        <>
          <MdCancel className="mr-1 mt-1 h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 uppercase first-letter:capitalize dark:bg-red-50">
            <span className="px-2 text-center text-red-500">{value}</span>
          </p>
        </>
      )}

      {status === "PENDING" && (
        <>
          <FaClock className="mr-1 mt-1 h-5 w-5 text-orange-500" />
          <p className="rounded-lg bg-orange-100 py-1 pl-0 pr-1 uppercase first-letter:capitalize dark:bg-orange-50">
            <span className="px-2 text-center text-orange-500">{value}</span>
          </p>
        </>
      )}
    </>
  );
};

export default StatusStyle;

import axios from 'axios'
import { APIPath } from 'baserURL'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { VscError } from "react-icons/vsc";
import { BiSolidError } from 'react-icons/bi';
import DivLoader from 'components/divloader/DivLoader';
import parse from 'html-react-parser';

function PaymentInternalRedirectPage() {
    const [isLoading, setLoading] = useState(true)
    const [isReload, setisReload] = useState(false)
    const [paymentUrl, setpaymentUrl] = useState("")
    const { gateway_id, gateway_uuid } = useParams()
    const navigate = useNavigate()
    const fetchPaymentURI = async () => {
        try {
            const apiurl = APIPath + `/api/v1/payment/internal-return-redirect-uri/${gateway_id}/${gateway_uuid}`
            const res = await axios.get(apiurl)
            // console.log("res", res)
            if (res?.data?.internal_return_redirect_uri_available) {
                setisReload(true)
                window.location.replace(res?.data?.internal_return_redirect_uri)
            }

        } catch (error) {
            console.log("error", error)

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchPaymentURI()
    }, [])
    const Loader = () => {
        if (isLoading) {
            return <div className='mt-5 pt-5'>
                <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                <p className='text-center'>Loading please wait...</p>

            </div>
        }
    }

    return (
        <div className='bg-white'>

            {
            isLoading 
            ? Loader() 
            : paymentUrl 
            ?<>
            <div className=' h-full bg-white'>
           { parse(paymentUrl)} 
            </div>
            </>
            :isReload?<></>:
                <div className='h-screen flex flex-col justify-center'>
                    <div className='self-center '>
                        <BiSolidError className="mx-auto text-6xl text-red-500" />
                    </div>
                    <h5 className='self-center text-black-500 opacity-50'>Something went wrong, please check the url.</h5>
                </div>
            }
        </div>
    )
}

export default PaymentInternalRedirectPage
